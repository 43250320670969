import 'react-modern-drawer/dist/index.css';

import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import {
  HiOutlineBell,
  HiOutlineCash,
  HiOutlineCheckCircle,
  HiOutlineCog,
  HiOutlineGift,
  HiOutlineHeart,
  HiOutlineHome,
  HiOutlineLogout,
  HiOutlineQrcode,
  HiOutlineShoppingCart,
  HiOutlineX,
} from 'react-icons/hi';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Drawer from 'react-modern-drawer';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { logout } from '../../features/auth/authSlice';
import { resetTreatmentBalance } from '../../features/user/userSlice';
import axiosApiInstance from '../../utils/api';
import Subtitle from '../atoms/Subtitle';

function SideDrawer({ isOpen, isOnClose }) {
  const dispatch = useDispatch();
  const [t] = useTranslation('common');

  const personal = JSON.parse(localStorage.getItem('personal'));

  const { user, isSuccess } = useSelector((state) => state.auth);

  // eslint-disable-next-line no-unused-vars
  const [token, setToken, removeToken] = useCookies(['accessToken']);

  const getPersonal = useCallback(async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token.accessToken || null}`,
      },
    };

    const response = await axiosApiInstance.get(`${process.env.REACT_APP_API_HOST}/api/v1/users/me`, config);

    if (response.data) {
      localStorage.setItem('personal', JSON.stringify(response.data));
    }

    return response.data;
  }, [token]);

  useEffect(() => {
    if (isSuccess || user) {
      getPersonal();
    }
  }, [user, isSuccess, getPersonal]);

  const handleLogout = () => {
    dispatch(logout()).then(() => {
      dispatch(resetTreatmentBalance());
      removeToken('accessToken');
    });
  };

  return (
    <Drawer
      open={isOpen}
      onClose={isOnClose}
      direction="left"
      className="!w-full"
      overlayOpacity={1}
      overlayColor="#ffffff40"
    >
      <>
        <div className="w-full flex flex-col pt-8 justify-center items-center bg-primary">
          <button
            id="btn_close_hamburger"
            type="button"
            className="absolute top-3 left-6 bg-[#46355759] flex items-center justify-center w-10 h-10 rounded-lg text-xl text-white"
            onClick={isOnClose}
          >
            <HiOutlineX />
          </button>
          <Link to="/profile/personal" className="block mb-6 no-underline">
            <LazyLoadImage
              src={`${process.env.PUBLIC_URL}/assets/images/user.jpg`}
              className="w-32 h-32 overflow-hidden rounded-full border border-[#A988CC]/75 block mx-auto"
              alt="user"
            />
          </Link>
          <div className="w-full pt-4 pb-8 bg-secondary/40 rounded-t-2xl">
            <p className="font-body text-base text-white font-medium block m-0">
              <Link to="/profile/personal" className="flex items-center justify-center no-underline">
                <HiOutlineQrcode className="text-2xl mr-1 block" />
                {personal ? personal.data.name : ''}
              </Link>
            </p>
          </div>
        </div>
        <div className="relative z-10 pt-8 px-6 bg-white rounded-2xl -mt-4">
          <Subtitle title={t('your_activity')} body={t('check_your_activity')} />
          <div className="block mb-6 your-activity">
            <Link
              id="nav_notification"
              to="/notification"
              className="font-body flex py-3 text-sm items-center border-b border-[#efedf1] text-secondary your-activity-item"
            >
              <HiOutlineBell className="text-lg mr-2" />
              <span className="block tracking-wider">{t('notification')}</span>
            </Link>
            {process.env.REACT_APP_ENABLE_ECOMMERCE === 'yes' ? (
              <Link
                id="nav_cart"
                to="/cart"
                className="font-body flex py-3 text-sm items-center border-b border-[#efedf1] text-secondary your-activity-item"
              >
                <HiOutlineShoppingCart className="text-lg mr-2" />
                <span className="block tracking-wider">{t('cart')}</span>
              </Link>
            ) : null}

            {process.env.REACT_APP_ENABLE_ECOMMERCE === 'yes' ? (
              <Link
                id="nav_wishlist"
                to="/wishlist"
                className="font-body flex py-3 text-sm items-center border-b border-[#efedf1] text-secondary your-activity-item"
              >
                <HiOutlineHeart className="text-lg mr-2" />
                <span className="block tracking-wider">{t('wishlist')}</span>
              </Link>
            ) : null}

            {process.env.REACT_APP_ENABLE_ECOMMERCE === 'yes' ? (
              <Link
                id="nav_ecommerce_transaction"
                to="/transaction"
                className="font-body flex py-3 text-sm items-center border-b border-[#efedf1] text-secondary your-activity-item"
              >
                <HiOutlineGift className="text-lg mr-2" />
                <span className="block tracking-wider">{t('transaction')}</span>
              </Link>
            ) : null}

            <Link
              id="nav_treatment_balance"
              to="/treatment-balance"
              className="font-body flex py-3 text-sm items-center border-b border-[#efedf1] text-secondary your-activity-item"
            >
              <HiOutlineCash className="text-lg mr-2" />
              <span className="block tracking-wider">{t('treatment_balance')}</span>
            </Link>

            <Link
              id="nav_treatment_transaction"
              to="/treatment-transaction"
              className="font-body flex py-3 text-sm items-center border-b border-[#efedf1] text-secondary your-activity-item"
            >
              <HiOutlineCheckCircle className="text-lg mr-2" />
              <span className="block tracking-wider">{t('treatment_transaction')}</span>
            </Link>
          </div>
          <Subtitle title={t('setting')} body={t('change_app_setting')} />
          <div className="block mb-4 your-setting">
            <Link
              to="/profile/account"
              className="font-body flex py-3 text-sm items-center border-b border-[#efedf1] text-secondary your-setting-item"
            >
              <HiOutlineCog className="text-lg mr-2" />
              <span className="block tracking-wider">{t('account')}</span>
            </Link>
            {process.env.REACT_APP_ENABLE_ECOMMERCE === 'yes' ? (
              <Link
                to="/shipping/address"
                className="font-body flex py-3 text-sm items-center border-b border-[#efedf1] text-secondary your-setting-item"
              >
                <HiOutlineHome className="text-lg mr-2" />
                <span className="block tracking-wider">{t('address')}</span>
              </Link>
            ) : null}

            <button
              data-cy="btn_logout"
              type="button"
              onClick={handleLogout}
              className="font-body flex py-3 text-sm items-center border-b-0 border-[#efedf1] text-secondary your-setting-item"
            >
              <HiOutlineLogout className="text-lg mr-2" />
              <span className="block tracking-wider">{t('signout')}</span>
            </button>
          </div>
        </div>
      </>
    </Drawer>
  );
}

SideDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isOnClose: PropTypes.func.isRequired,
};

export default React.memo(SideDrawer);
