import cx from 'classnames';
import React, { useEffect, useState } from 'react';

function Quantity({ amount, onAdd, onMinus }) {
  const [counter, setCounter] = useState(amount || 1);
  const [disabled, setDisabled] = useState(true);

  const increase = () => {
    onAdd(counter + 1);
    setCounter((count) => count + 1);
  };

  const decrease = () => {
    if (counter > 1) {
      onMinus(counter - 1);
      setCounter((count) => count - 1);
    }
  };

  useEffect(() => {
    if (counter === 1) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [counter]);

  function handleChange(e) {
    setCounter(e.target.value);
  }

  return (
    <div className="relative flex">
      <button
        type="button"
        className={cx(
          'w-8 min-w-[32px] h-8 inline-flex justify-center items-center bg-primary/10 p-0 text-primary leading-none rounded-tl rounded-bl',
          {
            '!text-primary/50 pointer-events-none': disabled,
          },
        )}
        onClick={decrease}
        disabled={disabled}
      >
        <svg
          width="16"
          height="3"
          viewBox="0 0 16 3"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={cx('w-4 h-4', {
            'fill-primary/50': disabled,
            'fill-primary': !disabled,
          })}
        >
          <path
            d="M15.8261 0.5H0.173913C0.0782609 0.5 0 0.594737 0 0.710526V2.28947C0 2.40526 0.0782609 2.5 0.173913 2.5H15.8261C15.9217 2.5 16 2.40526 16 2.28947V0.710526C16 0.594737 15.9217 0.5 15.8261 0.5Z"
            fill="inherit"
          />
        </svg>
      </button>
      <input
        type="text"
        id="quantity"
        name="quantity"
        value={counter}
        onChange={handleChange}
        placeholder="0"
        className="w-8 min-w-[32px] h-8 px-0 text-center border-none font-sans text-[#454547]/60 text-sm font-medium outline-none ring-0 shadow-none"
      />
      <button
        type="button"
        className="w-8 min-w-[32px] h-8 inline-flex justify-center items-center bg-primary/10 text-primary p-0 leading-none rounded-tr rounded-br"
        onClick={increase}
      >
        <svg
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="fill-primary w-4 h-4"
        >
          <path
            d="M7.2104 0.5H8.78935C8.9297 0.5 8.99988 0.559259 8.99988 0.677778V16.3222C8.99988 16.4407 8.9297 16.5 8.78935 16.5H7.2104C7.07005 16.5 6.99988 16.4407 6.99988 16.3222V0.677778C6.99988 0.559259 7.07005 0.5 7.2104 0.5Z"
            fill="inherit"
          />
          <path
            d="M0.186047 7.5H15.814C15.938 7.5 16 7.57018 16 7.71053V9.28947C16 9.42982 15.938 9.5 15.814 9.5H0.186047C0.0620155 9.5 0 9.42982 0 9.28947V7.71053C0 7.57018 0.0620155 7.5 0.186047 7.5Z"
            fill="inherit"
          />
        </svg>
      </button>
    </div>
  );
}

export default React.memo(Quantity);
