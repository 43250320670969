import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import NeosoftLogo from '../../assets/neosoft_dark.svg';

function Logo() {
  return <LazyLoadImage src={NeosoftLogo} className="w-full block" alt="neosoft logo" />;
}

export default React.memo(Logo);
