import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { set } from 'lodash';

import notificationService from './notificationService';

const initialState = {
  data: [],
  error: false,
  loading: false,
  count: {
    data: [],
    error: false,
    loading: false,
  },
};

export const getNotification = createAsyncThunk('user/get-notification', async (arg, thunkAPI) => {
  try {
    return await notificationService.getNotification(arg);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message && error.response.data.data.message) ||
      error.message ||
      error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

export const countNotification = createAsyncThunk('user/count-notification', async (arg, thunkAPI) => {
  try {
    return await notificationService.countNotification(arg);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message && error.response.data.data.message) ||
      error.message ||
      error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNotification.pending, (state) => {
        set(state, 'loading', true);
      })
      .addCase(getNotification.fulfilled, (state, action) => {
        set(state, 'loading', false);
        set(state, 'data', action.payload);
      })
      .addCase(getNotification.rejected, (state) => {
        set(state, 'loading', false);
        set(state, 'error', true);
      })
      .addCase(countNotification.pending, (state) => {
        set(state, 'count.loading', true);
      })
      .addCase(countNotification.fulfilled, (state, action) => {
        set(state, 'count.loading', false);
        set(state, 'count.data', action.payload);
      })
      .addCase(countNotification.rejected, (state) => {
        set(state, 'count.loading', false);
        set(state, 'count.error', true);
      });
  },
});

export default notificationSlice.reducer;
