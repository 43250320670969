export function getPageMaxScroll() {
  // Cross browser page height detection is ugly
  return (
    Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight,
    ) - window.innerHeight
  ); // Subtract viewport height
}

export const transformObjtoQuery = (obj) => {
  let result = '?';
  for (const proper in obj) {
    if (result === '?') {
      result += `${proper}=${obj[proper]}`;
    } else {
      result += `&${proper}=${obj[proper]}`;
    }
  }
  return result;
};

export const apiHeaderConfig = () => {
  const user = JSON.parse(localStorage.getItem('user')) || null;
  if (user) {
    return {
      headers: {
        Authorization: `Bearer ${user?.data?.accessToken}`,
      },
    };
  }
  return {};
};

export const formatRupiah = (param) => {
  const reverse = param.toString().split('').reverse().join('');
  const ribuan = reverse.match(/\d{1,3}/g);
  return ribuan.join('.').split('').reverse().join('');
};
