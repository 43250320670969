import React, { useState } from 'react';
import { HiOutlineBell, HiOutlineMenu, HiOutlineShoppingCart } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';

import useKeypress from '../../customHook/useKeyPress';
import SideDrawer from './SideDrawer';

function Header({ cartCounter, notificationCounter }) {
  const history = useHistory();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen((prevState) => !prevState);
  };

  useKeypress('Escape', () => {
    if (isDrawerOpen) {
      setIsDrawerOpen(false);
    }
  });
  return (
    <>
      <header className="fixed left-0 right-0 top-0 z-40 w-full rounded-br-[1.25rem] sm:rounded-br-none rounded-bl-[1.25rem] sm:rounded-bl-none bg-transparent transition-all ease-in-out duration-500 shadow-none">
        <div className="max-w-screen-sm mx-auto flex items-center h-16 px-6 justify-between">
          <div className="flex items-center">
            <button
              id="btn_hamburger"
              type="button"
              className="my-first-step relative bg-[#46355759] flex items-center justify-center w-10 h-10 rounded-lg text-xl text-white"
              onClick={toggleDrawer}
              tour="step-3"
            >
              <HiOutlineMenu />
            </button>
          </div>
          <div className="flex items-center">
            <button
              type="button"
              className="relative bg-[#46355759] flex items-center justify-center w-10 h-10 rounded-lg text-xl text-white ml-2"
              tour="step-1"
              onClick={() => {
                history.push('/notification');
              }}
            >
              <HiOutlineBell />
              {notificationCounter > 0 ? (
                <span className="absolute -top-0.5 -right-0.5 rounded-sm bg-[#FF7272] w-4 h-4 block text-center text-xs text-white tracking-tight">
                  {notificationCounter}
                </span>
              ) : null}
            </button>
            <button
              type="button"
              className="relative bg-[#46355759] flex items-center justify-center w-10 h-10 rounded-lg text-xl text-white ml-2"
              tour="step-2"
              onClick={() => {
                history.push('/cart');
              }}
            >
              <HiOutlineShoppingCart />

              {cartCounter > 0 ? (
                <span className="absolute -top-0.5 -right-0.5 rounded-sm bg-[#FF7272] w-4 h-4 block text-center text-xs text-white tracking-tight">
                  {cartCounter}
                </span>
              ) : null}
            </button>
          </div>
        </div>
      </header>
      <SideDrawer isOpen={isDrawerOpen} isOnClose={toggleDrawer} />
    </>
  );
}

export default React.memo(Header);
