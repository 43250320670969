/* eslint-disable import/no-unresolved */
import 'swiper/scss';

import PropTypes from 'prop-types';
import React from 'react';
import { A11y, Autoplay } from 'swiper';
import { Swiper } from 'swiper/react';

function CarouselBanner({ children }) {
  return (
    <Swiper
      className="overflow-visible"
      modules={[Autoplay, A11y]}
      loop
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      loopAdditionalSlides={3}
      centeredSlides
      spaceBetween={16}
      slidesPerView={1}
    >
      {children}
    </Swiper>
  );
}

CarouselBanner.propTypes = {
  children: PropTypes.node.isRequired,
};

export default React.memo(CarouselBanner);
