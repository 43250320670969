import cx from 'classnames';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

const addVariants = {
  hidden: {
    opacity: 0,
    y: 100,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.4,
    },
  },
  exit: {
    opacity: 0,
    y: 100,
  },
};

function ScanButton() {
  const history = useHistory();

  const [addToggle, setAddToggle] = useState(false);

  const handleMenuToggle = () => {
    setAddToggle(!addToggle);

    history.push('/qrscan');
  };

  const btnLinkClass =
    'fixed right-6 bottom-6 z-20 w-14 h-14 flex items-center justify-center rounded-full m-auto text-white text-3xl bg-primary shadow-sm transition-all duration-500 ease-in-out outline-none focus:ring-0';

  return (
    <motion.button
      variants={addVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      type="button"
      className={cx(`${btnLinkClass} scale-110 rotate-0`, {
        '!scale-100': addToggle,
      })}
      onClick={handleMenuToggle}
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1210_10498)">
          <path
            d="M16 0H24V6.66667H21.3333V2.66667H16V0ZM8 0V2.66667H2.66667V6.66667H0V0H8ZM16 24V21.3333H21.3333V17.3333H24V24H16ZM8 24H0V17.3333H2.66667V21.3333H8V24ZM0 10.6667H24V13.3333H0V10.6667Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_1210_10498">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </motion.button>
  );
}

export default React.memo(ScanButton);
