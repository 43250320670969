import axios from 'axios';
import { get } from 'lodash';

import CONSTANT from '../constant/index';
import AuthUtils from './auth';

const refreshToken = async (payload) =>
  axios.post(`${process.env.REACT_APP_API_HOST}${CONSTANT.USERS_API_URL}refreshToken`, payload);
const axiosApiInstance = axios.create();

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config) => config,
  (error) => {
    Promise.reject(error);
  },
);

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    // eslint-disable-next-line no-underscore-dangle
    if (error.response.status === 401 && !originalRequest._retry) {
      // eslint-disable-next-line no-underscore-dangle
      originalRequest._retry = true;
      try {
        const user = JSON.parse(localStorage.getItem('user'));
        const getRefreshToken = await refreshToken({
          refreshToken: user.data.refreshToken,
        });
        // eslint-disable-next-line no-param-reassign
        error.response.config.headers.Authorization = `Bearer ${getRefreshToken.data.data.accessToken}`;
        user.data.refreshToken = getRefreshToken.data.data.refreshToken;
        user.data.accessToken = getRefreshToken.data.data.accessToken;
        localStorage.setItem('user', JSON.stringify(user));
        return axios(error.response.config);
      } catch (e) {
        /* Refresh token failed then do the logout, direct to login page */
        if (get(e, 'response.status') && e.response.status === 403) {
          await AuthUtils.logout();
          document.cookie = 'accessToken=; Max-Age=0';
          window.location.href = '/signin';
        }
      }
    }
    return Promise.reject(error);
  },
);

export default axiosApiInstance;
