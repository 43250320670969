import { combineReducers } from '@reduxjs/toolkit';

import addressReducer from '../features/address/addressSlice';
import appointmentReducer from '../features/appointment/appointmentSlice';
import authReducer from '../features/auth/authSlice';
import bannerReducer from '../features/banner/bannerSlice';
import cartReducer from '../features/cart/cartSlice';
import clinicReducer from '../features/clinic/clinicSlice';
import notificationReducer from '../features/notification/notificationSlice';
import productReducer from '../features/product/productSlice';
import shippingReducer from '../features/shipping/shippingSlice';
import therapistReducer from '../features/therapist/therapistSlice';
import userReducer from '../features/user/userSlice';
import wishlistReducer from '../features/wishlist/wishlistSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  cart: cartReducer,
  banner: bannerReducer,
  appointment: appointmentReducer,
  clinic: clinicReducer,
  product: productReducer,
  user: userReducer,
  wishlist: wishlistReducer,
  shipping: shippingReducer,
  address: addressReducer,
  notification: notificationReducer,
  therapist: therapistReducer,
});

export default rootReducer;
