import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

function ItemNotification({ title, desc, href, status, seen }) {
  return (
    <Link
      to={href}
      className={cx('flex items-center py-3 px-6 border-b border-[#e6e6e6]', {
        '!bg-[#FFEDAE]': seen,
      })}
    >
      {status === 'confirm' && (
        <i className="w-14 h-14 min-w-[56px] inline-flex justify-center items-center mr-3">
          <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12 42.5C14.2091 42.5 16 40.7091 16 38.5C16 36.2909 14.2091 34.5 12 34.5C9.79086 34.5 8 36.2909 8 38.5C8 40.7091 9.79086 42.5 12 42.5Z"
              stroke="#A988CC"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M34 42.5C36.2091 42.5 38 40.7091 38 38.5C38 36.2909 36.2091 34.5 34 34.5C31.7909 34.5 30 36.2909 30 38.5C30 40.7091 31.7909 42.5 34 42.5Z"
              stroke="#A988CC"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M34 34.5H12V6.5H8" stroke="#A988CC" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
            <path
              d="M12 10.5L40 12.5L38 26.5H12"
              stroke="#A988CC"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </i>
      )}
      {status === 'success' && (
        <i className="w-14 h-14 min-w-[56px] inline-flex justify-center items-center mr-3">
          <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M24 4.5L29.253 8.332L35.756 8.32L37.753 14.508L43.021 18.32L41 24.5L43.021 30.68L37.753 34.492L35.756 40.68L29.253 40.668L24 44.5L18.747 40.668L12.244 40.68L10.247 34.492L4.979 30.68L7 24.5L4.979 18.32L10.247 14.508L12.244 8.32L18.747 8.332L24 4.5Z"
              stroke="#A988CC"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17 24.5L22 29.5L32 19.5"
              stroke="#A988CC"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </i>
      )}
      {status === 'process' && (
        <i className="w-14 h-14 min-w-[56px] inline-flex justify-center items-center mr-3">
          <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M17.25 17.5H8.25C7.71957 17.5 7.21086 17.2893 6.83579 16.9142C6.46071 16.5391 6.25 16.0304 6.25 15.5V6.5C6.25 5.96957 6.46071 5.46086 6.83579 5.08579C7.21086 4.71071 7.71957 4.5 8.25 4.5C8.78043 4.5 9.28914 4.71071 9.66421 5.08579C10.0393 5.46086 10.25 5.96957 10.25 6.5V13.5H17.25C17.7804 13.5 18.2891 13.7107 18.6642 14.0858C19.0393 14.4609 19.25 14.9696 19.25 15.5C19.25 16.0304 19.0393 16.5391 18.6642 16.9142C18.2891 17.2893 17.7804 17.5 17.25 17.5Z"
              fill="#A988CC"
            />
            <path
              d="M42.0001 26.5002C41.4696 26.5002 40.9609 26.2895 40.5858 25.9144C40.2108 25.5393 40.0001 25.0306 40.0001 24.5002C40.0016 20.9793 38.8409 17.5563 36.6982 14.7624C34.5555 11.9685 31.5506 9.95999 28.1497 9.04845C24.7488 8.13691 21.1422 8.37336 17.8894 9.7211C14.6366 11.0688 11.9197 13.4525 10.1601 16.5022C9.8943 16.9613 9.45707 17.296 8.94453 17.4327C8.432 17.5694 7.88614 17.497 7.42705 17.2312C6.96796 16.9655 6.63324 16.5282 6.49653 16.0157C6.35981 15.5031 6.4323 14.9573 6.69805 14.4982C8.89856 10.6867 12.2954 7.7079 16.3617 6.02398C20.4279 4.34006 24.9362 4.04514 29.1872 5.18498C33.4382 6.32482 37.1942 8.83569 39.8725 12.3281C42.5508 15.8204 44.0017 20.0991 44.0001 24.5002C44.0001 25.0306 43.7893 25.5393 43.4143 25.9144C43.0392 26.2895 42.5305 26.5002 42.0001 26.5002ZM39.7501 44.5002C39.2196 44.5002 38.7109 44.2895 38.3358 43.9144C37.9608 43.5393 37.7501 43.0306 37.7501 42.5002V35.5002H30.7501C30.2196 35.5002 29.7109 35.2895 29.3358 34.9144C28.9608 34.5393 28.7501 34.0306 28.7501 33.5002C28.7501 32.9698 28.9608 32.4611 29.3358 32.086C29.7109 31.7109 30.2196 31.5002 30.7501 31.5002H39.7501C40.2805 31.5002 40.7892 31.7109 41.1643 32.086C41.5393 32.4611 41.7501 32.9698 41.7501 33.5002V42.5002C41.7501 43.0306 41.5393 43.5393 41.1643 43.9144C40.7892 44.2895 40.2805 44.5002 39.7501 44.5002Z"
              fill="#A988CC"
            />
            <path
              d="M24 44.5C18.6976 44.4936 13.6142 42.3845 9.86488 38.6351C6.11553 34.8858 4.00635 29.8024 4 24.5C4 23.9696 4.21071 23.4609 4.58579 23.0858C4.96086 22.7107 5.46957 22.5 6 22.5C6.53043 22.5 7.03914 22.7107 7.41421 23.0858C7.78929 23.4609 8 23.9696 8 24.5C7.99848 28.0209 9.15912 31.4439 11.3018 34.2378C13.4445 37.0317 16.4494 39.0402 19.8503 39.9518C23.2512 40.8633 26.8579 40.6268 30.1106 39.2791C33.3634 37.9314 36.0804 35.5477 37.84 32.498C37.9716 32.2707 38.1467 32.0715 38.3552 31.9118C38.5638 31.7522 38.8017 31.6352 39.0555 31.5675C39.3093 31.4998 39.5739 31.4827 39.8343 31.5173C40.0947 31.5519 40.3457 31.6374 40.573 31.769C40.8003 31.9006 40.9995 32.0757 41.1592 32.2842C41.3188 32.4928 41.4358 32.7307 41.5035 32.9845C41.5712 33.2383 41.5883 33.5029 41.5537 33.7633C41.5191 34.0237 41.4336 34.2747 41.302 34.502C39.543 37.535 37.02 40.054 33.9842 41.8082C30.9485 43.5624 27.5061 44.4905 24 44.5Z"
              fill="#A988CC"
            />
          </svg>
        </i>
      )}
      {status === 'deliver' && (
        <i className="w-14 h-14 min-w-[56px] inline-flex justify-center items-center mr-3">
          <svg width="48" height="39" viewBox="0 0 48 39" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2042_11012)">
              <path
                d="M46.8 26.6998H45.6V18.5923C45.6 17.6398 45.2175 16.7248 44.5425 16.0498L37.05 8.55731C36.375 7.8823 35.46 7.4998 34.5075 7.4998H31.2V3.8998C31.2 1.9123 29.5875 0.299805 27.6 0.299805H8.4C6.4125 0.299805 4.8 1.9123 4.8 3.8998V7.4998H0.6C0.27 7.4998 0 7.7698 0 8.0998V9.2998C0 9.6298 0.27 9.89981 0.6 9.89981H21C21.33 9.89981 21.6 10.1698 21.6 10.4998V11.6998C21.6 12.0298 21.33 12.2998 21 12.2998H3C2.67 12.2998 2.4 12.5698 2.4 12.8998V14.0998C2.4 14.4298 2.67 14.6998 3 14.6998H18.6C18.93 14.6998 19.2 14.9698 19.2 15.2998V16.4998C19.2 16.8298 18.93 17.0998 18.6 17.0998H0.6C0.27 17.0998 0 17.3698 0 17.6998V18.8998C0 19.2298 0.27 19.4998 0.6 19.4998H16.2C16.53 19.4998 16.8 19.7698 16.8 20.0998V21.2998C16.8 21.6298 16.53 21.8998 16.2 21.8998H4.8V31.4998C4.8 35.4748 8.025 38.6998 12 38.6998C15.975 38.6998 19.2 35.4748 19.2 31.4998H28.8C28.8 35.4748 32.025 38.6998 36 38.6998C39.975 38.6998 43.2 35.4748 43.2 31.4998H46.8C47.46 31.4998 48 30.9598 48 30.2998V27.8998C48 27.2398 47.46 26.6998 46.8 26.6998ZM12 35.0998C10.0125 35.0998 8.4 33.4873 8.4 31.4998C8.4 29.5123 10.0125 27.8998 12 27.8998C13.9875 27.8998 15.6 29.5123 15.6 31.4998C15.6 33.4873 13.9875 35.0998 12 35.0998ZM36 35.0998C34.0125 35.0998 32.4 33.4873 32.4 31.4998C32.4 29.5123 34.0125 27.8998 36 27.8998C37.9875 27.8998 39.6 29.5123 39.6 31.4998C39.6 33.4873 37.9875 35.0998 36 35.0998ZM42 19.4998H31.2V11.0998H34.5075L42 18.5923V19.4998Z"
                fill="#A988CC"
              />
            </g>
            <defs>
              <clipPath id="clip0_2042_11012">
                <rect width="48" height="38.4" fill="white" transform="translate(0 0.299805)" />
              </clipPath>
            </defs>
          </svg>
        </i>
      )}
      <div className="relative block">
        <span className="font-body font-normal text-xs text-[#454547]/60">Kamis, 16 Jun ‘22 10:52 PM</span>
        <h5 className="font-display font-normal text-sm text-[#454547]/90 mb-0">{title}</h5>
        <p className="font-body font-normal text-xs text-[#454547]/40">{desc}</p>
      </div>
    </Link>
  );
}

ItemNotification.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  status: PropTypes.oneOf(['confirm', 'success', 'process', 'deliver']).isRequired,
  seen: PropTypes.oneOfType([PropTypes.bool]),
};

ItemNotification.defaultProps = {
  seen: true,
};

export default React.memo(ItemNotification);
