import axiosApiInstance from '../../utils/api';
import { apiHeaderConfig, transformObjtoQuery } from '../../utils/helpers';

const API_URL_WISHLIST = '/api/v1/clinics/therapists';
const get = async (arg) => {
  const query = transformObjtoQuery({
    type: arg.type,
    outlet_id: arg.outlet_id,
  });
  const response = await axiosApiInstance.get(
    `${process.env.REACT_APP_API_HOST}${API_URL_WISHLIST}${query}`,
    apiHeaderConfig(),
  );
  return response.data.data;
};

const TherapistService = {
  get,
};
export default TherapistService;
