import axiosApiInstance from '../../utils/api';
import { apiHeaderConfig } from '../../utils/helpers';

const API_URL = '/api/v1/clinics/';

// @Get Onboarding Data
const getClinicByHost = async () => {
  const reqBody = {
    host: `${window.location.origin}`,
  };

  const response = await axiosApiInstance.post(`${process.env.REACT_APP_API_HOST}${API_URL}get-by-host`, reqBody);

  if (response.data) {
    localStorage.setItem('clinic', JSON.stringify(response.data));
  }

  return response.data;
};

// Get Clinic Outlets
const getOutlets = async (arg) => {
  const response = await axiosApiInstance.get(
    `${process.env.REACT_APP_API_HOST}${API_URL}outlets${arg?.query || ''}`,
    apiHeaderConfig(),
  );

  return response.data.data;
};

const clinicService = {
  getClinicByHost,
  getOutlets,
};

export default clinicService;
