import axiosApiInstance from '../../utils/api';
import { apiHeaderConfig, transformObjtoQuery } from '../../utils/helpers';

const API_URL_WISHLIST = '/api/v1/whishlist';
const checkProductExistInWishlist = async (arg = {}) => {
  const query = arg.product_treatment_code
    ? transformObjtoQuery({
        product_treatment_code: arg.product_treatment_code,
      })
    : '';
  const response = await axiosApiInstance.get(
    `${process.env.REACT_APP_API_HOST}${API_URL_WISHLIST}${query}`,
    apiHeaderConfig(),
  );
  return response.data.data;
};

const create = async (arg = {}) => {
  const response = await axiosApiInstance.post(
    `${process.env.REACT_APP_API_HOST}${API_URL_WISHLIST}`,
    arg.payload,
    apiHeaderConfig(),
  );
  return response.data.data;
};

const deleteWishlist = async (arg = {}) => {
  const query = transformObjtoQuery({
    ...(arg.product_treatment_code
      ? {
          product_treatment_code: arg.product_treatment_code,
        }
      : {}),
    ...(arg.id
      ? {
          id: arg.id,
        }
      : {}),
  });
  const response = await axiosApiInstance.delete(
    `${process.env.REACT_APP_API_HOST}${API_URL_WISHLIST}${query}`,
    apiHeaderConfig(),
  );
  return response.data.data;
};

const AddressService = {
  deleteWishlist,
  checkProductExistInWishlist,
  create,
};
export default AddressService;
