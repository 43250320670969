import { Image } from 'antd';
import React from 'react';
import CurrencyFormat from 'react-currency-format';

import CONSTANT from '../../constant';

function ItemProduct({ item, setBottomSheetProduct, setProductPopUpData }) {
  return (
    <div
      aria-hidden
      onClick={() => {
        setProductPopUpData(item);
        setBottomSheetProduct(true);
      }}
      key={item.id}
      className="relative bg-white block w-full shadow-sm rounded-lg overflow-hidden"
    >
      <Image
        preview={false}
        src={`${item.url_img}` || CONSTANT.DEFAULT_FALLBACK_IMAGE}
        fallback={CONSTANT.DEFAULT_FALLBACK_IMAGE}
        className="w-full h-28  md:w-48 md:h-auto"
        alt={item.product_treatment_code}
      />

      <div className="relative p-2">
        <p className="font-body text-sm font-normal text-[#4A4A4A] mb-1 line-clamp-2">{item.product_treatment_name}</p>
        <span className="block font-body text-sm text-[#8B6AAE] font-bold">
          <CurrencyFormat
            value={item.price_ecommerce}
            displayType="text"
            decimalSeparator=","
            thousandSeparator="."
            prefix="Rp "
          />
        </span>
      </div>
    </div>
  );
}

export default React.memo(ItemProduct);
