import { useState } from 'react';
import { HiEye, HiEyeOff } from 'react-icons/hi';

const usePasswordToggle = (idEyeIcon) => {
  const [isVisible, setIsVisible] = useState(false);

  const Button = (
    <button
      id={idEyeIcon}
      type="button"
      className="absolute bottom-0 text-[1.25rem] right-0 h-[34px] w-[24px] flex items-center justify-center text-[#463557] opacity-[.5]"
      onClick={() => setIsVisible((state) => !state)}
      tabIndex="-1"
    >
      {!isVisible ? <HiEye /> : <HiEyeOff />}
    </button>
  );

  const InputType = isVisible ? 'text' : 'password';

  return [InputType, Button];
};

export default usePasswordToggle;
