import axiosApiInstance from '../../utils/api';
import { apiHeaderConfig } from '../../utils/helpers';

const get = async (query = '') => {
  const response = await axiosApiInstance.get(
    `${process.env.REACT_APP_API_HOST}/api/v1/banner${query}`,
    apiHeaderConfig(),
  );
  return response.data.data;
};
const bannerService = {
  get,
};

export default bannerService;
