const CONSTANT = {};
CONSTANT.DEFAULT_FALLBACK_IMAGE =
  'https://neosoft-technology.s3.ap-southeast-1.amazonaws.com/neosoft3_ecommerce/thumb.png';
CONSTANT.USERS_API_URL = '/api/v1/users/';
CONSTANT.DIRECT_BUY_PRODUCT = 'DIRECT_BUY_PRODUCT';
CONSTANT.APPOINTMENT_STATUS = [
  'book',
  'arrived',
  'consultation',
  'confirmed',
  'preparation',
  'process',
  'complete',
  'cancelled',
  'finished',
  'no_show',
];
CONSTANT.APPOINTMENT_CHANNEL_NAME = 'appointment';
export default CONSTANT;
