import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React from 'react';

const textLine = {
  hidden: {
    opacity: 0,
    transition: {
      staggerChildren: 0.5,
    },
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.6,
      duration: 0.4,
    },
  },
};

const startLine = {
  hidden: {
    opacity: 0,
    x: '100vw',
  },
  visible: {
    opacity: 1,
    x: -10,
    transition: {
      delay: 0.4,
    },
  },
  transition: {
    duration: 0.2,
    type: 'spring',
    stiffness: 50,
  },
};

const endLine = {
  hidden: {
    opacity: 0,
    x: '-100vw',
  },
  visible: {
    opacity: 1,
    x: 10,
    transition: {
      delay: 0.4,
    },
  },
  transition: {
    duration: 0.2,
    type: 'spring',
    stiffness: 50,
  },
};

function Divider({ text }) {
  return (
    <div className="relative w-full flex font-body text-[#454547E5] text-xs tracking-wide font-bold my-4 text-center">
      <motion.em
        className="relative flex-1 before:content-[''] before:w-full before:h-0.5 before:bg-[#454547E5] before:absolute before:left-0 before:top-0 before:bottom-0 before:m-auto after:content-[''] after:absolute after:top-0 after:bottom-0 after:right-0 after:w-2 after:h-2 after:m-auto after:bg-[#454547E5] after:rotate-45"
        variants={startLine}
        initial="hidden"
        animate="visible"
      />
      <motion.span className="block mx-4 text-sm" variants={textLine} initial="hidden" animate="visible">
        {text}
      </motion.span>
      <motion.em
        className="relative flex-1 before:content-[''] before:w-full before:h-0.5 before:bg-[#454547E5] before:absolute before:right-0 before:top-0 before:bottom-0 before:m-auto after:content-[''] after:absolute after:top-0 after:bottom-0 after:left-0 after:w-2 after:h-2 after:m-auto after:bg-[#454547E5] after:rotate-45"
        variants={endLine}
        initial="hidden"
        animate="visible"
      />
    </div>
  );
}

Divider.propTypes = {
  text: PropTypes.string.isRequired,
};

export default React.memo(Divider);
