/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/prop-types */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
import 'react-toastify/dist/ReactToastify.css';

import { notification } from 'antd';
import { AnimatePresence } from 'framer-motion';
import Pusher from 'pusher-js';
import { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { SpinnerLoading } from '../components';
import CONSTANT from '../constant/index';
import { getClinicByHost } from '../features/clinic/clinicSlice';
/* import { fetchToken, onMessageListener } from '../firebase';
import { requestFirebaseNotificationPermission } from '../firebaseInit'; */
import routes from '../routes';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function PrivateRoute({ children, ...rest }) {
  const { user, isSuccess } = useSelector((state) => state.auth);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isSuccess || user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              search: `?redirect_to="${location.pathname}"`,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  /* const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: '', body: '' });
  const [isTokenFound, setTokenFound] = useState(false);
  /!* fetchToken(setTokenFound); *!/

  requestFirebaseNotificationPermission()
    .then((firebaseToken) => {
      // eslint-disable-next-line no-console
      console.log(firebaseToken);
    })
    .catch((err) => err);
  onMessageListener().then((payload) => {
    setNotification({ title: payload.notification.title, body: payload.notification.body });
    setShow(true);
    console.log(payload);
  }).catch((err) => console.log('failed: ', err));

  const onShowNotificationClicked = () => {
    setNotification({ title: 'Notification', body: 'This is a test notification' });
    setShow(true);
  }; */

  useEffect(() => {
    dispatch(getClinicByHost());
  }, [dispatch]);

  useEffect(() => {
    /* subscribe if under authenticated mode */
    /* subscribe to multi channel at this moment to appointment channel with various event */
    const pusher = new Pusher(process.env.REACT_APP_KEY, {
      cluster: process.env.REACT_APP_CLUSTER,
      encrypted: true,
    });
    if (localStorage.getItem('user')) {
      const parseUser = JSON.parse(localStorage.getItem('user'));
      for (let i = 0; i < CONSTANT.APPOINTMENT_STATUS.length - 1; i += 1) {
        const channel = pusher.subscribe(CONSTANT.APPOINTMENT_CHANNEL_NAME);
        const event = `${parseUser.data.email}:${CONSTANT.APPOINTMENT_STATUS[i]}`;
        channel.bind(event, (data) => {
          notification.open({
            duration: 0,
            message: data.message,
            description: data.description,
            onClick: () => {
              window.location.href = data.link;
            },
          });
        });
      }
    }
    return () => {
      pusher.unsubscribe(CONSTANT.APPOINTMENT_CHANNEL_NAME);
    };
  }, []);

  return (
    <div className="relative w-full bg-white max-w-screen-sm mx-auto">
      <ScrollToTop />
      <Suspense fallback={<SpinnerLoading />}>
        <AnimatePresence exitBeforeEnter>
          <Switch location={location} key={location.key}>
            {routes.map(({ path, isAuthenticated, Component }) =>
              isAuthenticated ? (
                <PrivateRoute key={path} exact path={path}>
                  <Component />
                </PrivateRoute>
              ) : (
                <Route key={path} exact path={path}>
                  <Component />
                </Route>
              ),
            )}
          </Switch>
        </AnimatePresence>
      </Suspense>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
