import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Drawer from 'react-modern-drawer';

function ItemTransaction({ status }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <>
      <div className="relative block rounded-md bg-white shadow-sm mb-4">
        <div className="flex">
          <span className="block p-3 pr-0">
            <LazyLoadImage
              src={`${process.env.PUBLIC_URL}/assets/images/product.jpg`}
              className="w-20 min-w-[80px] h-20 object-cover object-center overflow-hidden rounded"
              alt="treatment"
            />
          </span>
          <div className="relative p-3">
            <h3 className="font-display text-base font-medium text-dark/90 whitespace-nowrap overflow-hidden text-ellipsis">
              IX:S220623-0001
            </h3>
            <span className="font-body text-xs font-normal text-dark/60">Jumat, 6 Juni 2022</span>
            <p className="font-body text-xs font-normal text-dark/90">Pembersih Wajah dan Tubuh</p>
          </div>
        </div>
        <div className="relative p-3 flex justify-between items-center border-t border-solid border-dark/20">
          <p className="font-body font-normal text-sm text-primary">
            <span className="block text-xs text-dark/60">Total Belanja</span>
            Rp 123.456.789
          </p>
          <div className="flex">
            {status === 'receive' && (
              <span className="font-body font-normal inline-flex text-xs justify-center text-success bg-success/20 rounded items-center w-auto h-8 py-0 px-3">
                Barang Diterima
              </span>
            )}
            {status === 'payment' && (
              <span className="font-body font-normal inline-flex text-xs justify-center text-warning bg-warning/20 rounded items-center w-auto h-8 py-0 px-3">
                Menunggu Pembayaran
              </span>
            )}
            {status === 'pack' && (
              <span className="font-body font-normal inline-flex text-xs justify-center text-warning bg-warning/20 rounded items-center w-auto h-8 py-0 px-3">
                Dalam Pengemasan
              </span>
            )}
            {status === 'deliver' && (
              <span className="font-body font-normal inline-flex text-xs justify-center text-warning bg-warning/20 rounded items-center w-auto h-8 py-0 px-3">
                Dalam Pengiriman
              </span>
            )}
            {status === 'complain' && (
              <span className="font-body font-normal inline-flex text-xs justify-center text-danger bg-danger/20 rounded items-center w-auto h-8 py-0 px-3">
                Proses Komplain
              </span>
            )}
            <button
              type="button"
              onClick={() => toggleDrawer()}
              className="w-8 h-8 inline-flex justify-center items-center outline-none bg-transparent ring-0 rounded ml-3 shadow-[0px_0px_1px_0px_#45454780]"
            >
              <svg width="4" height="16" viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="4" height="4" fill="#454547" fillOpacity="0.9" />
                <rect y="6" width="4" height="4" fill="#454547" fillOpacity="0.9" />
                <rect y="12" width="4" height="4" fill="#454547" fillOpacity="0.9" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="bottom"
        className="bg-white shadow-sm pt-10 pb-4 rounded-tr-lg rounded-tl-lg before:content-[''] before:absolute before:top-4 before:left-0 before:right-0 before:h-1 before:w-10 before:bg-[#DDDDDD] before:block before:m-auto before:rounded-xl"
        size="auto"
        overlayOpacity={1}
        overlayColor="#ffffff40"
        zIndex={100000}
      >
        <div className="w-full relative pt-6 px-6">
          <h4 className="font-display font-semibold text-dark text-center text-xl mb-2">Hapus Produk</h4>
          <p className="font-body font-normal text-center text-dark/50 mt-0 mb-4">
            Apakah kamu yakin ingin menghapus ini ?
          </p>
        </div>
        <div className="flex pt-2 px-6 pb-2">
          <button
            type="button"
            className="w-full tracking-wide block px-5 py-3.5 bg-primary hover:bg-[#8B69AE] focus:bg-[#8B69AE] active:bg-[#8B69AE]/90 font-display text-white font-normal text-base leading-tight rounded-[.625rem] focus:outline-none focus:ring-0 transition duration-150 ease-in-out mr-2"
            onClick={() => toggleDrawer()}
          >
            Batalkan
          </button>
          <button
            type="button"
            className="w-full tracking-wide block px-5 py-3.5 bg-primary/20 hover:bg-[#8B69AE] focus:bg-[#8B69AE] active:bg-[#8B69AE]/90 font-display text-primary hover:text-white focus:text-white active:text-white font-normal text-base leading-tight rounded-[.625rem] focus:outline-none focus:ring-0 transition duration-150 ease-in-out mr-2"
            onClick={() => toggleDrawer()}
          >
            <span className="underline">Yakin</span>
          </button>
        </div>
      </Drawer>
    </>
  );
}

ItemTransaction.propTypes = {
  status: PropTypes.oneOf(['receive', 'payment', 'pack', 'deliver', 'complain']).isRequired,
};

export default React.memo(ItemTransaction);
