/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import serviceClinic from './clinicService';

const initialState = {
  outlets: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  data: [],
  message: '',
};

// @Get Clinic By Host
export const getClinicByHost = createAsyncThunk('clinic/host', async (thunkAPI) => {
  try {
    return await serviceClinic.getClinicByHost();
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

// @Todo Get All Outlets
export const getAllOutlets = createAsyncThunk('clinic/outlets', async (outlets, thunkAPI) => {
  try {
    return await serviceClinic.getOutlets(outlets);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    toast.error(message);
    return thunkAPI.rejectWithValue(message);
  }
});

export const clinicSlice = createSlice({
  name: 'clinic',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClinicByHost.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getClinicByHost.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.data = action.payload;
      })
      .addCase(getClinicByHost.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error('Something not good to get your identity host');
      })
      .addCase(getAllOutlets.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllOutlets.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.outlets = action.payload;
      })
      .addCase(getAllOutlets.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = clinicSlice.actions;

export default clinicSlice.reducer;
