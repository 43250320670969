/* eslint-disable no-unused-vars */
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useLocation } from 'react-router-dom';

function IndicatorWizard({ step1, step2, step3, step4 }) {
  const location = useLocation();

  return (
    <div className="relative flex w-full justify-between mt-12 before:w-full before:h-0.5 before:bg-[#BA9ED5] before:absolute before:inset-0 before:m-auto">
      <span
        className={cx(
          'font-body text-xs font-medium text-[#45454799] inline-flex justify-center items-center w-6 h-6 bg-[#f6f6f6] rounded-full border-2 border-[#ba9ed5] scale-100',
          {
            '!scale-125': location.pathname === '/existing-patient/step-1',
            '!bg-[#ba9ed5] !text-white': step1,
          },
        )}
      >
        1
      </span>
      <span
        className={cx(
          'font-body text-xs font-medium text-[#45454799] inline-flex justify-center items-center w-6 h-6 bg-[#f6f6f6] rounded-full border-2 border-[#ba9ed5] scale-100',
          {
            '!scale-125': location.pathname === '/existing-patient/step-2',
            '!bg-[#ba9ed5] !text-white': step2,
          },
        )}
      >
        2
      </span>
      <span
        className={cx(
          'font-body text-xs font-medium text-[#45454799] inline-flex justify-center items-center w-6 h-6 bg-[#f6f6f6] rounded-full border-2 border-[#ba9ed5] scale-100',
          {
            '!scale-125': location.pathname === '/existing-patient/step-3',
            '!bg-[#ba9ed5] !text-white': step3,
          },
        )}
      >
        3
      </span>
      <span
        className={cx(
          'font-body text-xs font-medium text-[#45454799] inline-flex justify-center items-center w-6 h-6 bg-[#f6f6f6] rounded-full border-2 border-[#ba9ed5] scale-100',
          {
            '!scale-125': location.pathname === '/existing-patient/step-4',
            '!bg-[#ba9ed5] !text-white': step4,
          },
        )}
      >
        4
      </span>
    </div>
  );
}

IndicatorWizard.propTypes = {
  step1: PropTypes.oneOfType([PropTypes.bool.isRequired]),
  step2: PropTypes.oneOfType([PropTypes.bool.isRequired]),
  step3: PropTypes.oneOfType([PropTypes.bool.isRequired]),
  step4: PropTypes.oneOfType([PropTypes.bool.isRequired]),
};

IndicatorWizard.defaultProps = {
  step1: true,
  step2: false,
  step3: false,
  step4: false,
};

export default React.memo(IndicatorWizard);
