import axiosApiInstance from '../../utils/api';
import { apiHeaderConfig } from '../../utils/helpers';

const getNotification = async (query = '') => {
  const response = await axiosApiInstance.get(
    `${process.env.REACT_APP_API_HOST}/api/v1/notifications${query}`,
    apiHeaderConfig(),
  );
  return response.data.data;
};

const countNotification = async (query = '') => {
  const response = await axiosApiInstance.get(
    `${process.env.REACT_APP_API_HOST}/api/v1/notifications/count${query}`,
    apiHeaderConfig(),
  );
  return response.data.data;
};

const NotificationService = {
  getNotification,
  countNotification,
};
export default NotificationService;
