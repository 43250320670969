/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import bannerService from './bannerService';

// @Get data from localstorage
const initialState = {
  error: false,
  loading: false,
  data: [],
  message: '',
};

export const getBanner = createAsyncThunk('banner/get', async (arg, thunkAPI) => {
  try {
    return await bannerService.get(arg);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

export const bannerSlice = createSlice({
  name: 'banner',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBanner.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBanner.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getBanner.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.message = action.payload;
      });
  },
});

export default bannerSlice.reducer;
