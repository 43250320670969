/* import dateFormat from 'dateformat'; */
import React from 'react';

function ITemTreatmentBalance({
  id,
  name,
  balance,
  // eslint-disable-next-line camelcase
  expired_date,
  active,
  onclick,
}) {
  return (
    <div
      role="presentation"
      onClick={() => {
        onclick(name, id);
      }}
      className={`${
        onclick ? 'cursor-pointer' : ''
      } relative block rounded-md bg-white shadow-sm mb-4 w-full gap-4 cart-item ${
        active ? 'border-2 border-[#A988CC] border-solid' : ''
      }`}
    >
      <div className="flex">
        <span className="block p-3 pr-0" />
        <div className="relative p-3 w-full">
          <div className="flex">
            <span className="w-32 text-xs font-medium">Name</span>
            <h3 className="font-display text-base font-medium text-dark/90">{name}</h3>
          </div>
          <div className="flex">
            <span className="w-32 text-xs font-medium">Balance</span>
            <p
              style={{
                marginTop: '1px',
              }}
              className="font-body text-sm font-normal text-dark/60 pr-8"
            >
              {balance}
            </p>
          </div>

          <div className="flex">
            <span className="w-32 text-xs font-medium">Expired Date</span>
            <p
              style={{
                marginTop: '4px',
              }}
              className="font-body text-xs font-bold text-dark/60 pr-8"
            >
              {/* eslint-disable-next-line camelcase */}
              {expired_date}
              {/* {dateFormat(expired_date, 'dddd, d mmm yyyy')} */}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

ITemTreatmentBalance.propTypes = {};

export default React.memo(ITemTreatmentBalance);
