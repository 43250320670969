/* eslint-disable react/prop-types */
import 'react-modern-drawer/dist/index.css';

import dateFormat from 'dateformat';
import React from 'react';

function ItemHistory({ data }) {
  return (
    <button type="button" className="relative w-full block rounded-md bg-white shadow-sm mb-4">
      <div className="flex py-0.5">
        <span className="relative w-28 h-20 font-body font-medium  text-primary/75 tracking-wide flex flex-col items-center justify-center p-3 before:content-[''] before:h-[calc(100%_-_20%)] before:w-[1px] before:absolute before:top-0 before:bottom-0 before:right-0 before:bg-[#463557]/10 before:m-auto">
          <span className="flex items-center justify-center text-3xl font-body tracking-normal text-primary">
            <em className="not-italic  text-[#A988CC] ml-1">{data?.start_time.slice(0, -3)}</em>
          </span>
        </span>
        <div className="relative flex flex-col items-stretch justify-between text-left flex-1 p-3">
          <p className="font-body text-xl font-normal text-desc">{`${data?.outlet?.outlet_name}`}</p>
          <h6 className="font-body text-base font-normal text-accent">
            {dateFormat(data?.come_date, 'dddd, dS mmmm yyyy')}
          </h6>
          {data?.treatment_id && (
            <p className="font-body text-sm font-normal text-desc">{`${data?.appointment_therapist?.[0]?.therapist?.therapist_name}`}</p>
          )}
        </div>
      </div>
    </button>
  );
}

export default React.memo(ItemHistory);
