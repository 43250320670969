import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { set } from 'lodash';

import shippingService from './shippingService';

const initialState = {
  rates: {
    data: [],
    error: false,
    loading: false,
  },
  district: {
    data: [],
    error: false,
    loading: false,
  },
  city: {
    data: [],
    error: false,
    loading: false,
  },
  province: {
    data: [],
    error: false,
    loading: false,
  },
  area: {
    data: [],
    error: false,
    loading: false,
  },
};

export const getRates = createAsyncThunk('shipping/get-rates', async (arg, thunkAPI) => {
  try {
    return shippingService.getRates(arg);
  } catch (error) {
    return thunkAPI.rejectWithValue(error?.response?.data?.message);
  }
});

export const getDistrict = createAsyncThunk('shipping/get-district', async (arg, thunkAPI) => {
  try {
    return shippingService.getDistrict(arg);
  } catch (error) {
    return thunkAPI.rejectWithValue(error?.response?.data?.message);
  }
});
export const getCity = createAsyncThunk('shipping/get-city', async (arg, thunkAPI) => {
  try {
    return shippingService.getCity(arg);
  } catch (error) {
    return thunkAPI.rejectWithValue(error?.response?.data?.message);
  }
});
export const getProvince = createAsyncThunk('shipping/get-province', async (arg, thunkAPI) => {
  try {
    return shippingService.getProvince(arg);
  } catch (error) {
    return thunkAPI.rejectWithValue(error?.response?.data?.message);
  }
});
export const getArea = createAsyncThunk('shipping/get-area', async (arg, thunkAPI) => {
  try {
    return shippingService.getArea(arg);
  } catch (error) {
    return thunkAPI.rejectWithValue(error?.response?.data?.message);
  }
});
export const shippingSlice = createSlice({
  name: 'shipping',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getRates.pending, (state) => {
        set(state, 'rates.loading', true);
      })
      .addCase(getRates.fulfilled, (state, action) => {
        set(state, 'rates.loading', false);
        set(state, 'rates.data', action.payload);
      })
      .addCase(getRates.rejected, (state) => {
        set(state, 'rates.loading', false);
        set(state, 'rates.error', true);
      })

      .addCase(getDistrict.pending, (state) => {
        set(state, 'district.loading', true);
      })
      .addCase(getDistrict.fulfilled, (state, action) => {
        set(state, 'district.loading', false);
        set(state, 'district.data', action.payload);
      })
      .addCase(getDistrict.rejected, (state) => {
        set(state, 'district.loading', false);
        set(state, 'district.error', true);
      })

      .addCase(getCity.pending, (state) => {
        set(state, 'city.loading', true);
      })
      .addCase(getCity.fulfilled, (state, action) => {
        set(state, 'city.loading', false);
        set(state, 'city.data', action.payload);
      })
      .addCase(getCity.rejected, (state) => {
        set(state, 'city.loading', false);
        set(state, 'city.error', true);
      })

      .addCase(getProvince.pending, (state) => {
        set(state, 'province.loading', true);
      })
      .addCase(getProvince.fulfilled, (state, action) => {
        set(state, 'province.loading', false);
        set(state, 'province.data', action.payload);
      })
      .addCase(getProvince.rejected, (state) => {
        set(state, 'province.loading', false);
        set(state, 'province.error', true);
      })

      .addCase(getArea.pending, (state) => {
        set(state, 'area.loading', true);
      })
      .addCase(getArea.fulfilled, (state, action) => {
        set(state, 'area.loading', false);
        set(state, 'area.data', action.payload);
      })
      .addCase(getArea.rejected, (state) => {
        set(state, 'area.loading', false);
        set(state, 'area.error', true);
      });
  },
});

export default shippingSlice.reducer;
