/* eslint-disable react/prop-types */
import 'react-modern-drawer/dist/index.css';

import dateFormat from 'dateformat';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Drawer from 'react-modern-drawer';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import appointmentService from '../../features/appointment/appointmentService';
import { transformObjtoQuery } from '../../utils/helpers';

function ItemUpcoming({ data, onCancelSuccess, disableToggle = false }) {
  const history = useHistory();
  const [t] = useTranslation('common');
  const [isOpen, setIsOpen] = useState(false);
  const [activeAppointment, setActiveAppointment] = useState(null);
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const toggleDrawer = () => {
    if (disableToggle === false) {
      setIsOpen((prevState) => !prevState);
      setActiveAppointment(data);
    }
  };

  const toggleAlert = () => {
    setIsOpenAlert((prevState) => !prevState);

    setIsOpen(!isOpen);
  };

  const cancelAppointmentAction = async () => {
    const cancelAppointmentDispatch = await appointmentService.cancelAppointment({
      id_appointment: data?.id_appointments,
    });
    if (cancelAppointmentDispatch) {
      toast.success(t('appointment_cancelled'));
      setIsOpen(false);
      onCancelSuccess();
    }
  };

  const searchDetailQuery = transformObjtoQuery({
    tab: activeAppointment?.treatment_id,
    action: 'edit',
    id_appointments: activeAppointment?.id_appointments,
    outlet_id: activeAppointment?.outlet_id,
    outlet_name: activeAppointment?.outlet?.outlet_name,
    therapist_id: activeAppointment?.appointment_therapist?.[0]?.therapist_id,
    therapist_name: activeAppointment?.appointment_therapist?.[0]?.therapist?.therapist_name,
    treatment_id: activeAppointment?.treatment_id,
    come_date: activeAppointment?.come_date,
    start_time: activeAppointment?.start_time,
  });
  const handleSubmit = () => {
    setIsOpen(false);

    setIsOpenAlert(false);
  };

  return (
    <>
      <button type="button" onClick={toggleDrawer} className="relative w-full block rounded-md bg-white shadow-sm mb-4">
        <div className="flex py-0.5">
          <span className="relative w-28 h-20 font-body font-medium  text-primary/75 tracking-wide flex flex-col items-center justify-center p-3 before:content-[''] before:h-[calc(100%_-_20%)] before:w-[1px] before:absolute before:top-0 before:bottom-0 before:right-0 before:bg-[#463557]/10 before:m-auto">
            <span className="flex items-center justify-center text-3xl font-body tracking-normal text-primary">
              <em className="not-italic  text-[#A988CC] ml-1">{data?.start_time.slice(0, -3)}</em>
            </span>
          </span>
          <div className="relative flex flex-col items-stretch justify-between text-left flex-1 p-3">
            <p className="font-body text-xl font-normal text-desc">{`${data?.outlet?.outlet_name}`}</p>
            <h6 className="font-body text-base font-normal text-accent">
              {dateFormat(data?.come_date, 'dddd, dS mmmm yyyy')}
            </h6>
            {data?.treatment_id && (
              <p className="font-body text-sm font-normal text-desc">{`${data?.appointment_therapist?.[0]?.therapist?.therapist_name}`}</p>
            )}
            {data?.treatment_id ? (
              <p className="font-body text-sm font-bold text-desc text-right">
                {data?.treatment_id === 1 ? 'Consultation' : 'Care'}
              </p>
            ) : null}
          </div>
        </div>
      </button>
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="bottom"
        className="bg-white shadow-sm pt-10 pb-4 rounded-tr-lg rounded-tl-lg before:content-[''] before:absolute before:top-4 before:left-0 before:right-0 before:h-1 before:w-10 before:bg-[#DDDDDD] before:block before:m-auto before:rounded-xl"
        size="auto"
        overlayOpacity={1}
        overlayColor="#ffffff40"
      >
        <button
          type="button"
          className="font-body text-sm flex justify-between items-center py-2 px-6 text-[#463557]/75 font-semibold"
          onClick={() => history.push('/qrcode')}
        >
          <span>{t('code_qr')}</span>
        </button>
        <button
          type="button"
          className="font-body text-sm flex justify-between items-center py-2 px-6 text-[#463557]/75 font-semibold"
          onClick={() =>
            history.push({
              pathname: '/e-appointment/create',
              search: `${searchDetailQuery}`,
            })
          }
        >
          <span>{t('change')}</span>
        </button>
        <button
          type="button"
          className="font-body text-sm flex justify-between items-center py-2 px-6 text-[#463557]/75 font-semibold"
          onClick={cancelAppointmentAction}
        >
          <span>{t('cancel')}</span>
          <span
            style={{
              marginTop: '2px',
            }}
            className="ml-1 text-xs font-medium text-red-600"
          >
            | Cannot be undone
          </span>
        </button>
      </Drawer>
      <Drawer
        open={isOpenAlert}
        onClose={toggleAlert}
        direction="bottom"
        className="bg-white shadow-sm pt-10 pb-4 rounded-tr-lg rounded-tl-lg before:content-[''] before:absolute before:top-4 before:left-0 before:right-0 before:h-1 before:w-10 before:bg-[#DDDDDD] before:block before:m-auto before:rounded-xl"
        size="auto"
        overlayOpacity={1}
        overlayColor="#ffffff40"
      >
        <div className="py-2 px-6 text-center">
          <h3 className="font-body text-lg font-bold text-[#4F4F4F]">Batalkan Jadwal</h3>
          <p className="font-body text-sm text-desc">Apakah kamu yakin ingin menghapus ini?</p>
          <div className="flex mt-4">
            <button
              type="button"
              className="font-body flex-auto text-sm tracking-wider bg-white py-3 mr-2 px-6 rounded-lg text-accent shadow-sm border-none"
              onClick={toggleAlert}
            >
              <b>Batal</b>
            </button>
            <button
              type="button"
              className="font-body flex-auto text-sm tracking-wider bg-primary py-3 ml-2 px-6 rounded-lg text-white shadow-sm border-none"
              onClick={handleSubmit}
            >
              <b>Yakin</b>
            </button>
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default React.memo(ItemUpcoming);
