/* eslint-disable react/prop-types */
import 'react-modern-drawer/dist/index.css';

import cx from 'classnames';
import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Drawer from 'react-modern-drawer';
import { Link } from 'react-router-dom';

function OutletItem({ data, selected, onHandleClick }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <>
      <div
        className={cx('relative block rounded-md bg-white shadow-sm mb-4', {
          '!shadow-[0_0_0_2px_#A988CC]': selected === (data.id_outlet ? data.id_outlet : ''),
        })}
      >
        <div onClick={onHandleClick} aria-hidden="true" className="cursor-pointer">
          <LazyLoadImage
            src={`${process.env.PUBLIC_URL}/assets/images/maps.jpg`}
            className="w-full h-[105px] object-cover object-center overflow-hidden rounded-tl-md rounded-tr-md"
            alt="map"
          />
        </div>
        <div className="relative p-3">
          <h3
            onClick={onHandleClick}
            aria-hidden="true"
            className="inline-block font-body text-base font-medium text-primary cursor-pointer"
          >
            {data.outlet_name ? data.outlet_name : ''}
          </h3>
          <p className="font-body text-xs font-normal text-desc pr-8">{data.address ? data.address : ''}</p>
          <button
            type="button"
            className="absolute z-40 right-4 bottom-4 flex justify-center items-center w-6 h-6 outline-none hover:outline-none shadow-none"
            onClick={toggleDrawer}
          >
            <svg width="16" height="4" viewBox="0 0 16 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 0H10V4H6V0ZM12 0H16V4H12V0ZM0 0H4V4H0V0Z" fill="#463557" />
            </svg>
          </button>
        </div>
      </div>
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="bottom"
        className="bg-white shadow-sm pt-10 pb-4 rounded-tr-lg rounded-tl-lg before:content-[''] before:absolute before:top-4 before:left-0 before:right-0 before:h-1 before:w-10 before:bg-[#DDDDDD] before:block before:m-auto before:rounded-xl"
        size="auto"
        overlayOpacity={1}
        overlayColor="#ffffff40"
      >
        <p className="font-body text-sm flex justify-between items-center py-2 px-6 text-[#463557BF] font-semibold">
          <span>Petunjuk Arah</span>
          <Link to="/" className="font-semibold text-[#4E65C6] underline">
            url://to.the.moon
          </Link>
        </p>
        <p className="font-body text-sm flex justify-between items-center py-2 px-6 text-[#463557BF] font-semibold">
          <span>Nomor Kontak</span>
          <Link to="/" className="font-semibold text-[#4E65C6] underline">
            {data.outlet_phone ? data.outlet_phone : ''}
          </Link>
        </p>
        <p className="font-body text-sm flex justify-between items-center py-2 px-6 text-[#463557BF] font-semibold">
          <span>Nomor Fax</span>
          <Link to="/" className="font-semibold text-[#4E65C6] underline">
            {data.outlet_fax ? data.outlet_fax : ''}
          </Link>
        </p>
        <p className="font-body text-sm flex justify-between items-center py-2 px-6 text-[#463557BF] font-semibold">
          <span>Jam Operasional</span>
          <Link to="/" className="font-semibold cursor-text pointer-events-none text-[#979797]">
            08:00 s/d 17:00 WIB
          </Link>
        </p>
      </Drawer>
    </>
  );
}

export default React.memo(OutletItem);
