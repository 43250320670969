// import { useState } from 'react';

import cx from 'classnames';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React from 'react';
import { HiPlus } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';

const addVariants = {
  hidden: {
    opacity: 0,
    y: 100,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.4,
    },
  },
  exit: {
    opacity: 0,
    y: 100,
  },
};

function AddButton({ href }) {
  const history = useHistory();

  // const [addToggle, setAddToggle] = useState(false);

  const handleMenuToggle = () => {
    // setAddToggle(!addToggle);

    history.push(href);
  };

  const btnLinkClass =
    'fixed right-6 bottom-6 z-20 w-14 h-14 flex items-center justify-center rounded-full m-auto text-white text-3xl bg-primary shadow-sm transition-all duration-500 ease-in-out outline-none focus:ring-0';

  return (
    <motion.button
      variants={addVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      type="button"
      className={cx(`${btnLinkClass} scale-110 rotate-0`, {
        // '!scale-100 !rotate-45': addToggle,
      })}
      onClick={handleMenuToggle}
    >
      <HiPlus />
    </motion.button>
  );
}

AddButton.propTypes = {
  href: PropTypes.string.isRequired,
};

export default React.memo(AddButton);
