import React, { useState } from 'react';
import { QrReader } from 'react-qr-reader';
import styled from 'styled-components';

const QrWrapper = styled.div`
  position: relative;
  width: 100%;
  section {
    margin-top: 2rem;
    margin-bottom: 2rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    > div {
      padding-top: 100% !important;
      transform: scale(1.5);
    }
  }
`;

const QrScope = styled.span`
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 80%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: auto;
`;

function QrReaderCode() {
  const [data, setData] = useState('QR Code Belum Terdeteksi!');

  return (
    <>
      <QrWrapper>
        <QrReader
          onResult={(result, error) => {
            if (result) {
              setData(result?.text);
            } else if (error) {
              // eslint-disable-next-line no-console
              console.info(error);
            }
          }}
          style={{ width: '100%' }}
          containerStyle={{
            backgroundImage: `url(${process.env.PUBLIC_URL}assets/images/qrc_bg.svg)`,
          }}
        />
        <QrScope
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}assets/images/qrc_scope.svg)`,
          }}
        />
      </QrWrapper>
      <p className="text-center text-sm opacity-50">{data}</p>
    </>
  );
}

export default React.memo(QrReaderCode);
