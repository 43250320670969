import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isEmpty, isUndefined, set } from 'lodash';

import userService from './userService';

const initialState = {
  guidance: {
    data: [],
    error: false,
    loading: false,
  },
  updateGuidance: {
    data: [],
    error: false,
    loading: false,
  },
  userAddress: {
    data: [],
    error: false,
    loading: false,
  },
  defaultAddress: {
    data: [],
    error: false,
    loading: false,
  },
  deleteUserAddress: {
    data: [],
    error: false,
    loading: false,
  },
  editAddress: {
    data: [],
    error: false,
    loading: false,
  },
  createAddress: {
    data: [],
    error: false,
    loading: false,
  },
  treatmentBalance: {
    data: [],
    error: false,
    loading: false,
    loadingMore: false,
    pagination: {},
  },
  treatmentTransaction: {
    data: [],
    error: false,
    loading: false,
    loadingMore: false,
    pagination: {},
  },
};

export const getUserGuidance = createAsyncThunk('user/get-guidance', async (arg, thunkAPI) => {
  try {
    return await userService.getGuidance(arg);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message && error.response.data.data.message) ||
      error.message ||
      error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

export const getDefaultAddress = createAsyncThunk('user/get-default-address', async (arg, thunkAPI) => {
  try {
    return userService.getDefaultAddress(arg);
  } catch (error) {
    return thunkAPI.rejectWithValue(error?.response?.data?.message);
  }
});

export const deleteUserAddress = createAsyncThunk('user/delete-address', async (arg, thunkAPI) => {
  try {
    return userService.deleteAddress(arg);
  } catch (error) {
    return thunkAPI.rejectWithValue(error?.response?.data?.message);
  }
});

export const setDefaultAddress = createAsyncThunk('user/set-default-address', async (arg, thunkAPI) => {
  try {
    return userService.setDefaultAddress(arg);
  } catch (error) {
    return thunkAPI.rejectWithValue(error?.response?.data?.message);
  }
});

export const getUserAddress = createAsyncThunk('user/get-user-address', async (arg, thunkAPI) => {
  try {
    return userService.getUserAddress(arg);
  } catch (error) {
    return thunkAPI.rejectWithValue(error?.response?.data?.message);
  }
});

export const createUserAddress = createAsyncThunk('user/create-user-address', async (arg, thunkAPI) => {
  try {
    return userService.createUserAddress(arg);
  } catch (error) {
    return thunkAPI.rejectWithValue(error?.response?.data?.message);
  }
});
export const editUserAddress = createAsyncThunk('user/edit-user-address', async (arg, thunkAPI) => {
  try {
    return userService.editUserAddress(arg);
  } catch (error) {
    return thunkAPI.rejectWithValue(error?.response?.data?.message);
  }
});

export const getTreatmentTransaction = createAsyncThunk('user/get-treatment-transaction', async (arg, thunkAPI) => {
  try {
    return userService.getTreatmentTransaction(arg);
  } catch (error) {
    return thunkAPI.rejectWithValue(error?.response?.data?.message);
  }
});

export const getTreatmentBalance = createAsyncThunk('user/get-treatment-balance', async (arg, thunkAPI) => {
  try {
    return await userService.getTreatmentBalance(arg);
  } catch (error) {
    return thunkAPI.rejectWithValue(error?.response?.data?.message);
  }
});

export const updateUserGuidance = createAsyncThunk('user/update-guidance', async (arg, thunkAPI) => {
  try {
    return await userService.updateGuidance(arg);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message && error.response.data.data.message) ||
      error.message ||
      error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetTreatmentBalance: (state) => {
      set(state, 'treatmentBalance.data', []);
      set(state, 'treatmentBalance.error', false);
      set(state, 'treatmentBalance.loading', false);
      set(state, 'treatmentBalance.loadingMore', false);
      set(state, 'treatmentBalance.pagination', {});
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserGuidance.pending, (state) => {
        set(state, 'guidance.loading', true);
      })
      .addCase(getUserGuidance.fulfilled, (state, action) => {
        set(state, 'guidance.loading', false);
        set(state, 'guidance.data', action.payload[0]);
      })
      .addCase(getUserGuidance.rejected, (state) => {
        set(state, 'guidance.loading', false);
        set(state, 'guidance.error', true);
      })

      .addCase(getDefaultAddress.pending, (state) => {
        set(state, 'defaultAddress.loading', true);
      })
      .addCase(getDefaultAddress.fulfilled, (state, action) => {
        set(state, 'defaultAddress.loading', false);
        set(state, 'defaultAddress.data', action.payload);
      })
      .addCase(getDefaultAddress.rejected, (state) => {
        set(state, 'defaultAddress.loading', false);
        set(state, 'defaultAddress.error', true);
      })

      .addCase(getUserAddress.pending, (state) => {
        set(state, 'userAddress.loading', true);
      })
      .addCase(getUserAddress.fulfilled, (state, action) => {
        set(state, 'userAddress.loading', false);
        set(state, 'userAddress.data', action.payload);
      })
      .addCase(getUserAddress.rejected, (state) => {
        set(state, 'userAddress.loading', false);
        set(state, 'userAddress.error', true);
      })
      .addCase(deleteUserAddress.pending, (state) => {
        set(state, 'deleteUserAddress.loading', true);
      })
      .addCase(deleteUserAddress.fulfilled, (state, action) => {
        set(state, 'deleteUserAddress.loading', false);
        set(state, 'deleteUserAddress.data', action.payload);
      })
      .addCase(deleteUserAddress.rejected, (state) => {
        set(state, 'deleteUserAddress.loading', false);
        set(state, 'deleteUserAddress.error', true);
      })
      .addCase(editUserAddress.pending, (state) => {
        set(state, 'editAddress.loading', true);
      })
      .addCase(editUserAddress.fulfilled, (state, action) => {
        set(state, 'editAddress.loading', false);
        set(state, 'editAddress.data', action.payload);
      })
      .addCase(editUserAddress.rejected, (state) => {
        set(state, 'editAddress.loading', false);
        set(state, 'editAddress.error', true);
      })
      .addCase(createUserAddress.pending, (state) => {
        set(state, 'createAddress.loading', true);
      })
      .addCase(createUserAddress.fulfilled, (state, action) => {
        set(state, 'createAddress.loading', false);
        set(state, 'createAddress.data', action.payload);
      })
      .addCase(createUserAddress.rejected, (state) => {
        set(state, 'createAddress.loading', false);
        set(state, 'createAddress.error', true);
      })
      .addCase(updateUserGuidance.pending, (state) => {
        set(state, 'updateGuidance.loading', true);
      })
      .addCase(updateUserGuidance.fulfilled, (state, action) => {
        set(state, 'updateGuidance.loading', false);
        set(state, 'updateGuidance.data', action.payload);
      })
      .addCase(updateUserGuidance.rejected, (state) => {
        set(state, 'updateGuidance.loading', false);
        set(state, 'updateGuidance.error', true);
      })
      .addCase(getTreatmentTransaction.pending, (state, action) => {
        if (isUndefined(action.meta.arg)) {
          set(state, 'treatmentTransaction.loading', true);
        } else {
          set(state, 'treatmentTransaction.loadingMore', true);
        }
      })
      .addCase(getTreatmentTransaction.fulfilled, (state, action) => {
        set(state, 'treatmentTransaction.loading', false);
        set(state, 'treatmentTransaction.loadingMore', false);
        let condition = [];
        if (!isEmpty(action.payload)) {
          condition =
            action.payload.current_page === 1
              ? action.payload.data
              : [...(state.treatmentTransaction.data || []), ...action.payload.data];
        }
        set(state, 'treatmentTransaction.data', condition);
        if (!isEmpty(action.payload)) {
          set(state, 'treatmentTransaction.pagination', {
            current_page: action.payload.current_page,
            first_page_url: action.payload.first_page_url,
            from: action.payload.from,
            last_page: action.payload.last_page,
            last_page_url: action.payload.last_page_url,
            next_page_url: action.payload.next_page_url,
            path: action.payload.path,
            per_page: action.payload.per_page,
            prev_page_url: action.payload.prev_page_url,
            to: action.payload.to,
            total: action.payload.total,
          });
        }
      })
      .addCase(getTreatmentTransaction.rejected, (state) => {
        set(state, 'treatmentTransaction.loading', false);
        set(state, 'treatmentTransaction.error', true);
      })
      .addCase(getTreatmentBalance.pending, (state, action) => {
        if (isUndefined(action.meta.arg)) {
          set(state, 'treatmentBalance.loading', true);
        } else {
          set(state, 'treatmentBalance.loadingMore', true);
        }
      })
      .addCase(getTreatmentBalance.fulfilled, (state, action) => {
        set(state, 'treatmentBalance.loading', false);
        set(state, 'treatmentBalance.loadingMore', false);

        let condition = [];
        if (!isEmpty(action.payload)) {
          condition =
            action.payload.current_page === 1
              ? action.payload.data
              : [...(state.treatmentBalance.data || []), ...action.payload.data];
        }
        set(state, 'treatmentBalance.data', condition);
        if (!isEmpty(action.payload)) {
          set(state, 'treatmentBalance.pagination', {
            current_page: action.payload.current_page,
            first_page_url: action.payload.first_page_url,
            from: action.payload.from,
            last_page: action.payload.last_page,
            last_page_url: action.payload.last_page_url,
            next_page_url: action.payload.next_page_url,
            path: action.payload.path,
            per_page: action.payload.per_page,
            prev_page_url: action.payload.prev_page_url,
            to: action.payload.to,
            total: action.payload.total,
          });
        }
      })
      .addCase(getTreatmentBalance.rejected, (state) => {
        set(state, 'treatmentBalance.loading', false);
        set(state, 'treatmentBalance.error', true);
      });
  },
});

export const { resetTreatmentBalance } = userSlice.actions;
export default userSlice.reducer;
