import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { set } from 'lodash';

import therapistService from './therapistService';

const initialState = {
  data: [],
  error: false,
  loading: false,
};

export const getTherapist = createAsyncThunk('therapist/get', async (arg, thunkAPI) => {
  try {
    return await therapistService.get(arg);
  } catch (error) {
    return thunkAPI.rejectWithValue(error?.response?.data?.message);
  }
});

export const therapistSlice = createSlice({
  name: 'therapist',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getTherapist.pending, (state) => {
        set(state, 'loading', true);
      })
      .addCase(getTherapist.fulfilled, (state, action) => {
        set(state, 'loading', false);
        set(state, 'data', action.payload);
      })
      .addCase(getTherapist.rejected, (state) => {
        set(state, 'loading', false);
        set(state, 'error', true);
      });
  },
});

export default therapistSlice.reducer;
