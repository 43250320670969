import 'react-popper-tooltip/dist/styles.css';

import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';

function Subtitle({ title, body }) {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
    trigger: 'click',
    placement: 'top-end',
  });

  return (
    <div className="flex justify-between items-center mb-3">
      <h5
        className={cx('font-body text-base font-bold text-secondary m-0', {
          'relative z-50 !text-white': visible,
        })}
      >
        {title}
      </h5>
      <button
        type="button"
        className={cx({
          'relative z-50': visible,
        })}
        ref={setTriggerRef}
      >
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14 1C14.2652 1 14.5196 1.10536 14.7071 1.29289C14.8946 1.48043 15 1.73478 15 2V14C15 14.2652 14.8946 14.5196 14.7071 14.7071C14.5196 14.8946 14.2652 15 14 15H2C1.73478 15 1.48043 14.8946 1.29289 14.7071C1.10536 14.5196 1 14.2652 1 14V2C1 1.73478 1.10536 1.48043 1.29289 1.29289C1.48043 1.10536 1.73478 1 2 1H14ZM2 0C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2L0 14C0 14.5304 0.210714 15.0391 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16H14C14.5304 16 15.0391 15.7893 15.4142 15.4142C15.7893 15.0391 16 14.5304 16 14V2C16 1.46957 15.7893 0.960859 15.4142 0.585786C15.0391 0.210714 14.5304 0 14 0L2 0Z"
            fill={visible ? 'white' : '#463557'}
            fillOpacity={visible ? '1' : '0.75'}
          />
          <path
            d="M5.255 5.786C5.25363 5.81829 5.25889 5.85053 5.27044 5.88072C5.28198 5.91091 5.29959 5.93841 5.32216 5.96155C5.34473 5.98468 5.3718 6.00296 5.4017 6.01524C5.43159 6.02753 5.46369 6.03357 5.496 6.033H6.321C6.459 6.033 6.569 5.92 6.587 5.783C6.677 5.127 7.127 4.649 7.929 4.649C8.615 4.649 9.243 4.992 9.243 5.817C9.243 6.452 8.869 6.744 8.278 7.188C7.605 7.677 7.072 8.248 7.11 9.175L7.113 9.392C7.11405 9.45761 7.14086 9.52017 7.18763 9.5662C7.2344 9.61222 7.29739 9.63801 7.363 9.638H8.174C8.24031 9.638 8.3039 9.61166 8.35078 9.56478C8.39766 9.51789 8.424 9.4543 8.424 9.388V9.283C8.424 8.565 8.697 8.356 9.434 7.797C10.043 7.334 10.678 6.82 10.678 5.741C10.678 4.23 9.402 3.5 8.005 3.5C6.738 3.5 5.35 4.09 5.255 5.786ZM6.812 11.549C6.812 12.082 7.237 12.476 7.822 12.476C8.431 12.476 8.85 12.082 8.85 11.549C8.85 10.997 8.43 10.609 7.821 10.609C7.237 10.609 6.812 10.997 6.812 11.549Z"
            fill={visible ? 'white' : '#463557'}
            fillOpacity={visible ? '1' : '0.75'}
          />
        </svg>
      </button>
      {visible && (
        <>
          <div
            ref={setTooltipRef}
            {...getTooltipProps({
              className:
                'tooltip-container max-w-[280px] -mr-2 m-2 font-display p-3 text-sm text-dark/60 rounded-t-lg rounded-b-lg border border-primary shadow-none',
            })}
          >
            <div {...getArrowProps({ className: 'tooltip-arrow -ml-2' })} />
            {body}
          </div>
          <div className="fixed z-40 inset-0 w-full h-full bg-black/80" />
        </>
      )}
    </div>
  );
}

Subtitle.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
};

export default React.memo(Subtitle);
