import { List } from 'antd';
/* import dateFormat from 'dateformat'; */
import React, { useState } from 'react';
import Drawer from 'react-modern-drawer';

function ItemTreatmentTransaction({
  code,
  outlet,
  // eslint-disable-next-line camelcase
  usage_date,
  item,
}) {
  const [openItemsDrawer, setOpenItemsDrawer] = useState(false);
  const [itemTransaction, setItemTransaction] = useState([]);

  const toggleDrawer = () => {
    setOpenItemsDrawer((prevState) => !prevState);
  };

  const onClickItems = (itemParameter) => {
    setOpenItemsDrawer(true);
    setItemTransaction(itemParameter);
  };

  return (
    <div className="relative block rounded-md bg-white shadow-sm mb-4 w-full gap-4 cart-item">
      <div className="flex">
        <span className="block p-3 pr-0" />
        <div className="relative p-3 w-full">
          <div className="flex items-center">
            <span className="w-20 text-xs">Code</span>
            <h3 className="font-display text-base font-medium text-dark/90">{code}</h3>
          </div>

          <div className="flex items-center">
            <span className="w-20 text-xs">Outlet</span>
            <p
              style={{
                marginTop: '1px',
              }}
              className="font-body text-sm font-normal text-dark/60 pr-8"
            >
              {outlet}
            </p>
          </div>

          <div className="flex justify-between">
            <div className="flex grow items-center">
              <span
                style={{
                  marginTop: '3px',
                }}
                className="w-20 text-xs"
              >
                Usage Date
              </span>
              <p
                style={{
                  marginTop: '4px',
                }}
                className="font-body text-xs font-bold text-dark/60 pr-8"
              >
                {/* {dateFormat(usage_date, 'dddd, d mmm yyyy')} */}
                {/* eslint-disable-next-line camelcase */}
                {usage_date}
              </p>
            </div>

            <p
              role="presentation"
              onClick={() => {
                onClickItems(item);
              }}
              className="cursor-pointer text-xs text-primary font-medium"
            >
              See Items
            </p>
          </div>
        </div>
      </div>

      <Drawer
        open={openItemsDrawer}
        onClose={toggleDrawer}
        direction="bottom"
        className="bg-white shadow-sm pt-10 pb-4 rounded-tr-lg rounded-tl-lg before:content-[''] before:absolute before:top-4 before:left-0 before:right-0 before:h-1 before:w-10 before:bg-[#DDDDDD] before:block before:m-auto before:rounded-xl"
        size="auto"
        overlayOpacity={1}
        overlayColor="#ffffff40"
        zIndex={100000}
      >
        <List
          className="wishlist_item_wrapper"
          dataSource={itemTransaction}
          renderItem={(itemRender) => (
            <List.Item key={itemRender.inv_code}>
              <div className="relative p-3 w-full">
                <div className="flex items-center">
                  <span className="w-32 text-xs font-medium">Invoice Code</span>
                  <h3 className="font-display text-base font-medium text-dark/90">{itemRender.inv_code}</h3>
                </div>
                <div className="flex items-center">
                  <span className="w-32 text-xs font-medium">Name</span>
                  <p
                    style={{
                      marginTop: '1px',
                    }}
                    className="font-body text-sm font-normal text-dark/60"
                  >
                    {itemRender.name}
                  </p>
                </div>
                <div className="flex items-center">
                  <span className="w-32 text-xs font-medium">Used</span>
                  <p
                    style={{
                      marginTop: '1px',
                    }}
                    className="font-body text-sm font-normal text-dark/60"
                  >
                    {itemRender.used}
                  </p>
                </div>
                <div className="flex items-center">
                  <span className="w-32 text-xs font-medium">Balance</span>
                  <p
                    style={{
                      marginTop: '1px',
                    }}
                    className="font-body text-sm font-normal text-dark/60"
                  >
                    {itemRender.balance}
                  </p>
                </div>
                <div className="flex items-center">
                  <span className="w-32 text-xs font-medium">Therapist</span>
                  <p
                    style={{
                      marginTop: '1px',
                    }}
                    className="font-body text-sm font-normal text-dark/60 pr-8"
                  >
                    {itemRender.therapist[0]}
                  </p>
                </div>
              </div>
            </List.Item>
          )}
        />
        {/* Inv Code
        Name
        Used
        Balance
        Therapist */}
      </Drawer>
    </div>
  );
}

ItemTreatmentTransaction.propTypes = {};

export default React.memo(ItemTreatmentTransaction);
